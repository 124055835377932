<template>
  <div>
    <add />
  </div>
</template>

<script>
import add from './add';

// import LocationComponent from './LocationComponent';

export default {
  components: {
    add,
    // LocationComponent,
  },

  methods: {},
};
</script>
