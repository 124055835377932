<template>
  <div>
    <b-row class="justify-content-md-center mt-5">
      <b-col cols="3"> </b-col>
      <b-col>
        <b-card class="mb-0">
          <h3 class="mb-2">Manage Address</h3>

          <b-form-group
            label="Select city"
            invalid-feedback="City is required."
            ref="city"
          >
            <v-select
              id="city"
              v-model="myObj.city"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="cityName"
              placeholder="Select city"
              ref="city"
              @search:blur="CheckCity()"
            />
          </b-form-group>

          <b-form-group
            label="Address"
            invalid-feedback="Address is required."
            ref="address"
          >
            <b-form-textarea
              id="textarea-default"
              placeholder="Enter address"
              v-model="myObj.address"
              rows="3"
            />
          </b-form-group>

          <b-form-group
            label="Location"
            invalid-feedback="Location is required"
          >
            <input
              type="text"
              placeholder="Enter your location"
              v-model="nearby"
              style="width: 100%; "
              ref="autocomplete"
            />
          </b-form-group>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            block
            variant="primary"
            @click="SaveAddress()"
          >
            Save
          </b-button>
        </b-card>
      </b-col>
      <b-col cols="3"> </b-col>
    </b-row>
  </div>
</template>

<script src="https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDfQlHl9S5INrugHikvGk9LT1dvXqOMrkU"></script>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import store from '@/store';
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter';
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
//import Multiselect from "vue-multiselect";
import VueGallery from 'vue-gallery';

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormTextarea,
    //  BFormInput,
    BFormGroup,
    BButton,
    //BTable,
    //BImg ,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    //BPagination,
    vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      cityName: [
        'Abu Dhabi',
        'Al-Ain',
        'Dubai',
        'Sharjah',
        'Umm al-Qaiwain',
        'Fujairah',
        'Ajman',
        'Ra’s al-Khaimah',
      ],

      myObj: {
        Id: 0,
        address: '',
        mapAddress: '',
        longitude: '',
        latitude: '',
        city: '',
      },

      nearby: '',
      error: '',
      spinner: false,
      address: '',
      myAddress: '',
      area: '',
      lat: '',
      long: '',
    };
  },
  methods: {
    CheckAddress() {
      var elem = this.$refs['address'];
      console.log(elem.state);
      console.log(this.myObj.address);

      if (this.myObj.address !== '') {
        elem.state = true;
      } else {
        elem.state = false;
      }
    },
    CheckCity() {
      var elem = this.$refs['city'];
      console.log(elem.state);
      console.log(this.myObj.city);

      if (this.myObj.city !== '') {
        elem.state = true;
      } else {
        elem.state = false;
      }
    },
    checkTitle() {
      var elem = this.$refs['title'];
      return (elem.state = this.myObj.name.length > 2 ? true : false);
    },
    checkStatus() {
      if (this.myObj.status == '') {
        return (this.errors.status = true);
      } else {
        return (this.errors.status = false);
      }
    },
    OpenAddModal() {
      this.$bvModal.show('modal-login');
      this.myObj.fillingId = 0;
      this.myObj.vendorId = this.$store.state.userData.userID;
      this.myObj.name = '';
      this.myObj.status = '';
    },
    OpenEditModal(row) {
      this.$bvModal.show('modal-login');
      this.myObj.fillingId = row.fillingId;
      this.myObj.vendorId = row.vendorId;
      this.myObj.name = row.name;
      this.myObj.status = row.status;
    },

    SaveAddress() {
      this.CheckCity();
      this.CheckAddress();
      if (this.CheckCity() == false || this.CheckAddress() == false) {
        return 0;
      } else {
        this.myObj.latitude = this.lat + '';
        this.myObj.longitude = this.long + '';
        this.myObj.mapAddress = this.$refs.autocomplete.value;
        //Edit
        var axios = require('axios');
        // this.$store.state.userData.userID
        var config = {
          method: 'put',
          url: 'https://brandaway.fastech.pk/api/Vendors/UpdateVendorAddress',
          headers: {
            Authorization: 'bearer ' + this.$store.state.userData.token,
          },
          data: this.myObj,
        };

        axios(config)
          .then(response => {
            if (response.data.status === 'success') {
              console.log(response.data);
              this.LoadData();

              this.$bvToast.toast('Address updated.', {
                title: 'Success!',
                variant: 'success',
                toaster: 'b-toaster-bottom-center',
              });
            } else {
              this.$bvToast.toast('Something went wrong.', {
                title: 'Error!',
                variant: 'danger',
                toaster: 'b-toaster-bottom-center',
              });
            }

            //  this.data  =  response.data;
            //console.log(this.$store.state.userData.userID);
            //this.items = response.data;

            // console.log(this.items);
          })
          .catch(function(error) {
            console.log(error);
          });
      }
    },

    LoadData() {
      var axios = require('axios');
      // this.$store.state.userData.userID
      var config = {
        method: 'get',
        url:
          'https://brandaway.fastech.pk/api/Vendors/GetVendorAddress/' +
          this.$store.state.userData.userID,
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then(response => {
          this.myObj = response.data.data;
          console.log('#$', response.data.data);
          this.nearby = this.myObj.mapAddress;
          console.log(response.data.data);
        })
        .catch(function(error) {
          console.log(error);
        });
    },
  },
  mounted() {
    var autocomplete = new google.maps.places.Autocomplete(
      this.$refs['autocomplete'],
      {
        bounds: new google.maps.LatLngBounds(
          new google.maps.LatLng(23.4241, 53.8478),
        ),
      },
    );

    autocomplete.setComponentRestrictions({
      country: ['ae'],
    });

    autocomplete.addListener('place_changed', () => {
      var place = autocomplete.getPlace();

      console.log(
        'coordinates',
        place.geometry.location.lat() + '-' + place.geometry.location.lng(),
      );

      //this.$store.commit('setLat', place.geometry.location.lat());
      //this.$store.commit('setLong', place.geometry.location.lng());
      this.lat = place.geometry.location.lat();
      this.long = place.geometry.location.lng();

      console.log(place.geometry.location.lat(), place.geometry.location.lng());
      console.log(this.$refs.autocomplete.value);

      this.nearby = this.$refs.autocomplete.value;
    });

    // this.showLocationOnTheMap("25.2048", "55.2708");
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
